/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        //overwrite parent function
        show_login_form = function () {
          jQuery('.rb-sign-in').click();
        };

        $('#propertyGallery').lightSlider({
            gallery:true,
            item:1,
            loop:true,
            thumbItem:9,
            slideMargin:0,
            enableDrag: false,
            currentPagerPosition:'left',
            adaptiveHeight:true,
            onSliderLoad: function(el) {
                el.lightGallery({
                    selector: '#propertyGallery .lslide',
                    download: false,
                    autoplay: false,
                    autoplayControls: false,
                    enableDrag: true,
                    enableTouch: true
                });
            },
            responsive : [
               {
                   breakpoint:991,
                   settings: {
                     enableDrag: true,
                     enableTouch: true
                   }
               },
               {
                 breakpoint: 767,
                 settings: {
                   controls: false
                 }
               }
           ]
        });

       createMarker = function (county_state, size, i,id,lat,lng,pin,title,counter,image,price,single_first_type,single_first_action,link,city,area,rooms,baths,cleanprice,slug1,val1,how1,slug2,val2,how2,slug3,val3,how3,slug4,val4,how4,slug5, val5, how5, slug6, val6, how6 ,slug7 ,val7, how7, slug8, val8, how8, single_first_type_name, single_first_action_name ){

            var new_title='';
            var myLatLng = new google.maps.LatLng(lat,lng);
            var marker = null;
            if(mapfunctions_vars.custom_search==='yes'){
                new_title =  title.replace('%',' ');
                new_title = decodeURIComponent(  new_title.replace(/\+/g,' '));

                Encoder.EncodeType = "entity";

                new_title =Encoder.htmlDecode( new_title);


                marker = new google.maps.Marker({
                    position: myLatLng,
                    map: map,
                    icon: custompin(pin),
                    shape: shape,
                    title:new_title,
                    zIndex: counter,
                    image:image,
                    idul:id,
                    price:price,
                    category:single_first_type,
                    action:single_first_action,
                    link:link,
                    city:city,
                    area:area,
                    infoWindowIndex : i,
                    rooms:rooms,
                    baths:baths,
                    size:size,
                    county_state:county_state,
                    cleanprice:cleanprice,
                    category_name:single_first_type_name,
                    action_name:single_first_action_name,
                    slug1: slug1,
                    val1: val1,
                    howto1:how1,
                    slug2:slug2,
                    val2: val2,
                    howto2:how2,
                    slug3:slug3,
                    val3: val3,
                    howto3:how3,
                    slug4:slug4,
                    val4: val4,
                    howto4:how4,
                    slug5:slug5,
                    val5: val5,
                    howto5:how5,
                    slug6:slug6,
                    val6: val6,
                    howto6:how7,
                    slug7:slug7,
                    val7: val7,
                    howto7:how7,
                    slug8:slug8,
                    val8: val8,
                    howto8:how8
                    });

            }else{
                 marker = new google.maps.Marker({
                        position: myLatLng,
                        map: map,
                        icon: custompin(pin),
                        shape: shape,
                        title: title,
                        zIndex: counter,
                        image:image,
                        idul:id,
                        price:price,
                        category:single_first_type,
                        action:single_first_action,
                        link:link,
                        city:city,
                        area:area,
                        infoWindowIndex : i,
                        rooms:rooms,
                        baths:baths,
                        cleanprice:cleanprice,
                        size:size,
                        county_state:county_state,
                        category_name:single_first_type_name,
                        action_name:single_first_action_name
                       });


            }

            gmarkers.push(marker);

            google.maps.event.addListener(marker, 'click', function(event) {

                  //  new_open_close_map(1);

                    map_callback( new_open_close_map );
                    google.maps.event.trigger(map, 'resize');

                    if(this.image===''){
                         info_image='<img src="' + mapfunctions_vars.path + '/idxdefault.jpg" alt="image" />';
                     }else{
                         info_image=this.image;
                     }

                    var category         =   decodeURIComponent ( this.category.replace(/-/g,' ') );
                    var action           =   decodeURIComponent ( this.action.replace(/-/g,' ') );
                    var category_name    =   decodeURIComponent ( this.category_name.replace(/-/g,' ') );
                    var action_name      =   decodeURIComponent ( this.action_name.replace(/-/g,' ') );
                    var in_type          =   mapfunctions_vars.in_text;
                    if(category==='' || action===''){
                        in_type=" ";
                    }

                   var infobaths;
                   if(this.baths!==''){
                       infobaths ='<span id="infobath">'+this.baths+'</span>';
                   }else{
                       infobaths ='';
                   }

                   var inforooms;
                   if(this.rooms!==''){
                       inforooms='<span id="inforoom">'+this.rooms+'</span>';
                   }else{
                       inforooms='';
                   }

                   var infosize;
                   if(this.size!==''){
                       infosize ='<span id="infosize">'+this.size;
                       if(mapfunctions_vars.measure_sys==='ft'){
                           infosize = infosize+ ' ft<sup>2</sup>';
                       }else{
                           infosize = infosize+' m<sup>2</sup>';
                       }
                       infosize =infosize+'</span>';
                   }else{
                       infosize='';
                   }


                   var title=  this.title.substr(0, 45);
                   if(this.title.length > 45){
                       title=title+"...";
                   }

                    //infoBox.setContent('<div class="info_details"><span id="infocloser" onClick=\'javascript:infoBox.close();\' ></span><a href="'+this.link+'">'+info_image+'</a><a href="'+this.link+'" id="infobox_title">'+title+'</a><div class="prop_detailsx">'+category_name+" "+in_type+" "+action_name+'</div><div class="prop_pricex">'+this.price+infosize+infobaths+inforooms+'</div></div>' );
                    // infoBox.setContent('<img src="http://i.imgur.com/jRfjvrz.png" width="30"/>');
                    infoBox.open(map, this);
                    map.setCenter(this.position);


                    switch (infobox_width){
                      case 700:

                            if(mapfunctions_vars.listing_map === 'top'){
                                map.panBy(100,-150);
                            }else{
                                if(mapfunctions_vars.small_slider_t==='vertical'){
                                    map.panBy(300,-300);

                                }else{
                                    map.panBy(10,-110);
                                }
                           }

                           vertical_off=0;
                           break;
                      case 500:
                           map.panBy(50,-120);
                           break;
                      case 400:
                           map.panBy(100,-220);
                           break;
                      case 200:
                           map.panBy(20,-170);
                           break;
                     }

                    if (control_vars.show_adv_search_map_close === 'no') {
                        $('.search_wrapper').addClass('adv1_close');
                        adv_search_click();
                    }

                     close_adv_search();
                    });/////////////////////////////////// end event listener

                if(mapfunctions_vars.generated_pins!=='0'){
                    pan_to_last_pin(myLatLng);
                }


        };

        var removeClass = true;
        $(".mobileMenu a").click(function () {
            $("#sideNav").toggleClass('sideNav-open');
            $(".sideNavBody").toggleClass('sideNavBody-push');
            removeClass = false;
        });

        $(".sideNav").click(function() {
            removeClass = false;
        });

        $("html").click(function () {
            if (removeClass) {
                $("#sideNav").removeClass('sideNav-open');
                $(".sideNavBody").removeClass('sideNavBody-push');
            }
            removeClass = true;
        });

        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('.scrollup').fadeIn();
            } else {
                $('.scrollup').fadeOut();
            }
        });
        $('.scrollup').click(function () {
            $("html, body").animate({
                scrollTop: 0
            }, 600);
            return false;
        });


        jQuery('.icon-fav').click(function () {
          if ($(this).text()==='Follow This Property') {
            $(this).text('Stop Following This Property');
          } else {
            $(this).text('Follow This Property');
          }
        });

        if (jQuery('.nav-tabs').length>0) {
          if (jQuery(window).width()<=768) {
            jQuery('#request_showing_tab').text('Request');
            jQuery('#apply_for_this_rental_tab').text('Apply');
          } else {
            jQuery('#request_showing_tab').text('Request a Showing');
            jQuery('#apply_for_this_rental_tab').text('Apply For This Rental');
          }
          jQuery(window).resize (function () {
            if (jQuery(window).width()<=768) {
              jQuery('#request_showing_tab').text('Request');
              jQuery('#apply_for_this_rental_tab').text('Apply');
            } else {
              jQuery('#request_showing_tab').text('Request a Showing');
              jQuery('#apply_for_this_rental_tab').text('Apply For This Rental');
            }
          });
        }

      },
      finalize: function() {

        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
